// components/ErrorBoundary.js
import Router from "next/router";
import React, { useState, useEffect } from "react";

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (hasError) {
      // Redirect to 404 page or any custom error page
      Router.push("/error");
    }
  }, [hasError]);

  const handleCatchError = (error, errorInfo) => {
    // You can also log the error to an error reporting service
    console.error("Uncaught error:", error, errorInfo);
    setHasError(true);
  };

  if (hasError) {
    // You can render any custom fallback UI
    return null; // Or custom component
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default ErrorBoundary;

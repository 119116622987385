"use client";
import { useEffect } from "react";

interface IntercomClientComponentProps {
  userId?: string;
}

// Extend the Window interface to include the intercomSettings property
declare global {
  interface Window {
    intercomSettings: any;
    Intercom: any;
  }
}

const IntercomClientComponent: React.FC<IntercomClientComponentProps> = ({
  userId,
}) => {
  useEffect(() => {
    const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID;

    if (!INTERCOM_APP_ID) {
      console.error("Intercom App ID is missing");
      return;
    }

    const intercomSettings: any = {
      api_base: "https://api-iam.intercom.io",
      app_id: INTERCOM_APP_ID,
    };

    if (userId) {
      intercomSettings.user_id = userId;
      intercomSettings.test = "test";
    }

    window.intercomSettings = intercomSettings;

    if (window.Intercom) {
      window.Intercom("reattach_activator");
      window.Intercom("update", intercomSettings);
      // console.log("Intercom reattached");
    } else {
      const intercomScript = document.createElement("script");
      intercomScript.type = "text/javascript";
      intercomScript.async = true;
      intercomScript.src = `https://widget.intercom.io/widget/${INTERCOM_APP_ID}`;
      intercomScript.onload = () => window.Intercom("update", intercomSettings);
      document.body.appendChild(intercomScript);
    }
  }, [userId]);

  return null; // This component does not render anything visually.
};

export default IntercomClientComponent;

import { createContext, useContext, useState, useEffect } from "react";
import User from "client/model/user";
import { useRouter } from "next/router";
interface AppStateProviderProps {
  children?: any;
}
interface MyAppStateContext {
  drawerOpen?: boolean;
  authenticated?: boolean;
  setDrawerOpenState?: any;
  logIn?: any;
  logOut?: any;
  user?: any;
}

const AppStateContext = createContext<MyAppStateContext>({});

export function AppStateProvider({ children }: AppStateProviderProps) {
  const router = useRouter();

  var thereIsError = false;

  const setLocalStorage = (key: string, value?: User) => {
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {}
  };

  const getLocalStorage = (key: string, initialValue?: User) => {
    try {
      thereIsError = false;
      const value = window.localStorage.getItem(key);
      return value ? JSON.parse(value) : initialValue;
    } catch (e) {
      thereIsError = true;
      return undefined;
    }
  };

  const [open, setOpen] = useState(true);
  const [user, setUser] = useState(undefined);

  const logIn = () => {
    router.push("/api/auth/login");
  };

  const logOut = () => {
    setUser(undefined);
    router.push("/api/auth/login");
  };

  const sharedState = {
    drawerOpen: open,
    setDrawerOpenState: setOpen,
    logIn,
    logOut,
  };

  useEffect(() => {
    if (!thereIsError) {
      setLocalStorage("user", user);
      thereIsError = false;
    }
  }, [user]);

  return (
    <AppStateContext.Provider value={sharedState}>
      {children}
    </AppStateContext.Provider>
  );
}

export function useAppStateContext() {
  const state = useContext(AppStateContext);

  if (state === undefined) {
    throw new Error("useAppState must be used within a AppStateProvider");
  }

  return state;
}
